/* GRAPH TOOL BAR - figure out what */

.navbar {
    border-bottom: 1px solid #666666;
  }
  
  .btn-group {
    margin-right: 2px;
    border: none;
  }
  
  .btn-group .btn {
    padding: 10px 7px;
  }
  
  .example-tooltip-red {
    background: #000;
    font-size: 13px !important;
  }
  
  .btn-group i {
    font-size: 23px;
  }
  
  i.fa.fa-facebook-official:hover,
  i.fa.fa-file-pdf-o:hover,
  i.fa.fa-linkedin-square:hover,
  i.fa.fa-picture-o:hover {
    background: none;
  }
  
  .btn-group-vertical {
    margin-bottom: 6px;
  }
  
  .btn:hover {
    color: transparent;
  }
  
  .btn-group :nth-child(1) i {
    font-size: 26px;
    margin-top: 1px;
  }
  
  .btn-group :nth-child(1):hover {
    background: #000;
    color: #fff;
  }
  
  .btn-group :nth-child(2):hover {
    background: #b71c1c;
    color: #fff;
  }
  
  .btn-group :nth-child(3):hover {
    background: #395693;
    color: #fff;
  }
  
  .btn-group :nth-child(4):hover {
    background: #0076b3;
    color: #fff;
  }
  
  .glyphicon {
    margin-top: 4px;
    margin-bottom: 6px;
  }
  
  .btn > .caret {
    margin-left: 4px;
  }
  
  .toolbar-horizontal {
    position: fixed;
    top: 57px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0 0 6px 0;
    z-index: 10;
    right: 19.3%;
  }
    
  @media (max-width: 414px) {
    .toolbar-horizontal {
      position: static;
    }
    .btn-group {
      margin: auto;
    }
  }
  