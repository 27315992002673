// ██╗      █████╗ ██████╗ ███████╗██╗     ███████╗
// ██║     ██╔══██╗██╔══██╗██╔════╝██║     ██╔════╝
// ██║     ███████║██████╔╝█████╗  ██║     ███████╗
// ██║     ██╔══██║██╔══██╗██╔══╝  ██║     ╚════██║
// ███████╗██║  ██║██████╔╝███████╗███████╗███████║
// ╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝╚══════╝

// sets the global styling of header labels
.header-label {
    color: #646464;
    margin: 0px 0px 5px 0px;
    font-size: 18px;
    font-weight: 600;
}

// sets the global styling of primary labels
.primary-label {
    font-size: 15px;
    font-weight: 600;
    color: #858585;
    display: block;
}

// sets the global styling of primary labels
.secondary-label {
    font-weight: 500;
}

.warning-label {
    font-size: 13px;
    font-weight: 600;
    color: $warning;
    display: block;
}
