.separator-1 {
  height: 18px;
  background-color: rgb(63,138,77);
}
.separator-2 {
  height: 18px;
  background-color: rgb(63,138,77);
}
.separator-3 {
  height: 18px;
  background-color: rgb(63,138,77);
}
.separator-4 {
  height: 18px;
  background-color: rgb(63,138,77);
}