.graph {
  &__container {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    max-height: calc(100vh - 64px - 46px);
    overflow: hidden;
    display: flex;
  }
  &__content {
    width: 80%;
    border: $border-color solid;
    border-width: 0 1px 1px 0;
  }
  &__canvas {
    width: calc(100%);
    height: calc(100vh - 160px);
  }
  &__toolbar {
    &--export {
      position: fixed;
      top: 57px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 0;
      padding-right: 0;
      border-radius: 0 0 6px 0;
      z-index: 10;
      right: 19.3%;
    }
    &--filter {
      &-container {
        padding: 10px 10px 0px 10px;
        width: 20%;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 80vh;
      }
      &-tools {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &__search {
    width: 100%;
    display: flex;
    &-btn {
      background-color: black;
      color: white;
      border-radius: 0 .4rem .4rem 0;
      width: 100%;
      height: 30px;
      z-index: 9;
      padding: 0;
      text-align: center;
      margin-top: 10%;
      &:focus {
        outline: none;
      }
    }
    &-text-area {
      border-color: rgb(194, 194, 194);
      border-radius: .4rem 0 0 .4rem;
      width: 75%;
    }
  }
  &__slider {
    margin-top: 5rem !important;
  }
  &__disclaimer {
    font-size: 1rem;
    padding: 1rem;
    text-align: left;
    color: $card-text-color;
  }
}

.table-container {
    width: 100%;
    border: 1px $border-color solid;
}

.btn-group {
  border: none !important;
  width: 100%;
}

.button-text {
  display: none;
  &:hover {
    background-color: transparent !important;
  }
}

@media only screen and (max-width: 55em) {
  .graph {
    &__container {
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    &__content {
      width: 100%;
    }
    &__canvas {
      width: calc(100%);
      height: calc(100vh - 300px);
    }
    &__toolbar {
      &--export {
        position: static;
      }
      &--filter {
        &-container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          border: solid $border-color;
          border-width: 1px;
        }
        &-tools {
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          justify-content: space-between;
        }
        &-dividers {
          display: none;
        }
      }
    }
    &__slider {
      width: 35% !important;
    }
    // &__search {
    //   max-width: 35vw !important;
    // }
    &__disclaimer {
      padding: 1rem;
      text-align: justify;
      color: $card-text-color;
    }
  }

  .btn-group {
    border: none !important;
    width: 20%;
  }
  .button-text {
    display: block;
    padding: .5rem;
    &:hover {
      background-color: transparent !important;
    }
  }
}

@media only screen and (max-width: 45em) {
  .graph {
    &__container {
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: scroll;
      padding-top: 0;
    }
    &__content {
      width: 100%;
    }
    &__canvas {
      width: calc(100%);
      height: calc(100vh - 400px);
    }
    &__toolbar {
      &--export {
        position: static;
      }
      &--filter {
        &-container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          border: 0;
          border-top: 0.1px solid lightgray;
        }
        &-tools {
          display: flex;
          flex-direction: column;
        }
        &-dividers {
          display: none;
        }
      }
    }
    &__slider {
      width: 100% !important;
    }
    &__search {
      max-width: 100vw !important;
    }
    &__disclaimer {
      padding: 1rem;
      text-align: justify;
      color: $card-text-color;
    }
  }

  .btn-group {
    border: none !important;
    width: 100%;
    min-height: 70px;
  }

  .button-text {
    display: block;
    padding: .5rem;
    &:hover {
      background-color: transparent !important;
    }
  }
}

.tooltip-details {
  color: #fff;
  padding: 0px;
  margin: 0px;
  border-bottom: 0.2px solid #000;
  z-index: 99999999;
  padding-right: 10px;
  font-size: 12px;
}