// ██╗███╗   ███╗██████╗  ██████╗ ██████╗ ████████╗███████╗
// ██║████╗ ████║██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝██╔════╝
// ██║██╔████╔██║██████╔╝██║   ██║██████╔╝   ██║   ███████╗
// ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██╔══██╗   ██║   ╚════██║
// ██║██║ ╚═╝ ██║██║     ╚██████╔╝██║  ██║   ██║   ███████║
// ╚═╝╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

@import './assets/scss/master.scss';
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

// ██████╗  ██████╗  ██████╗ ████████╗
// ██╔══██╗██╔═══██╗██╔═══██╗╚══██╔══╝
// ██████╔╝██║   ██║██║   ██║   ██║   
// ██╔══██╗██║   ██║██║   ██║   ██║   
// ██║  ██║╚██████╔╝╚██████╔╝   ██║   
// ╚═╝  ╚═╝ ╚═════╝  ╚═════╝    ╚═╝   

// make 1rem = 10px for easier calculations on the fly
:root {
  font-size: 62.5%;
}

input {
  font-size: 16px !important;
}

// background: radial-gradient(ellipse at center,#585858 0,#232323 100%);
// background-color: #343434;


// @font-face {
//   font-family: HelveticaUltraLight;
//   src: url(assets/fonts/helvetica-neue-ultralight_bigfontsite.com.ttf) format('truetype');
// }

.toastr-custom{
  // background: #3a3a3a !important;
  background: #472871 !important;
  bottom: 30px;
}

/************ CUSTOM ZOOM CSS *************/

.meeting-client-inner{
  width: 48% !important;
  height: 60% !important;
  margin-left: 4.9%;
  margin-top: 4.4%;
}

.webinar-client-inner{
  width: auto !important;
  height: 100% !important;
}

.wc-container-left{
  width: 100% !important;
  height: 100% !important;
}

.sharee-container__viewport{
  width: 100% !important;
  height: auto !important;
  margin-right: 0px !important;
}

.video-avatar__avatar-title{
 width: auto !important;
 margin-top: 15% !important;
 height: 100%;
}

.join-dialog{
  width: 100% !important;
}

.sharee-container__canvas-outline, .sharee-container__indicator{
  display: none !important;
}

#zmmtg-root {
  display: none;
}

// .meeting-client-inner{
//   top: 7.9% !important;
//   left: 4.8% !important;
//   width: 48% !important;
//   // height: 417px !important;
//   height: auto !important;
// }

// #wc-container-left{
//   width: 100% !important;
// }

// #notificationManager{
//  display: none;
// }

// .webinar-client-inner {
//   width: auto !important;
//   height: auto !important;
//   display: contents;
// }


.gallery-video-container__video-frame{
  width: 100% !important;
  // left: -16.9% !important;
  display: contents;
}

.gallery-video-container__wrap{
 width: 100% !important;
 height: auto !important;
}

.gallery-video-container__main-view{
  margin-top: 0px !important;
  display: grid !important;
}

// .join-dialog{
//   width: 100% !important;
// }

#main-video{
  width: 108% !important;
  margin-left: -22px;
  margin-top: 63px;
}

.video-avatar__avatar{
 position: absolute;
}

.video-avatar__avatar-name{
  display: block !important;
  position: absolute;
  top: 0px;
  width: 100%;
  max-width: 50% !important;
  font-size:20px !important;
}

.sharee-container__viewport{
  height: auto !important;
  // flex-shrink: 1 !important;
  margin-top: 0px !important;
  width: 100% !important;
}

.sharee-sharing-indicator__tip{
 display: inline-table !important;
 padding: 3px 25px !important;
}

.speaker-active-container__video-frame{
 width: 100% !important;
 left: 0px !important;
}

.speaker-active-container__wrap{
 position: inherit !important;
}

#sharingViewOptions{
  display: none;
}

// .join-dialog {
//   height: 288px !important;
// }

#wc-footer {
  display: none;
}

#wc-content:hover #wc-footer {
  display: inline-flex !important;
}

.meeting-client-inner:hover #wc-footer {
  display: inline-flex !important;
}

.footer-button__button,
#audioOptionMenu {
  display: none;
}

.more-button {
  display: none !important;
}


.q-a-entry-button-container {
  display: none
}

.undefined {
  display: none
}

@media only screen and (max-width: 55em) {
  .meeting-client-inner {
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 62px;
    height: 417px !important;
  }
}