// ██╗   ██╗████████╗██╗██╗     ██╗████████╗██╗███████╗███████╗//
// ██║   ██║╚══██╔══╝██║██║     ██║╚══██╔══╝██║██╔════╝██╔════╝//
// ██║   ██║   ██║   ██║██║     ██║   ██║   ██║█████╗  ███████╗//
// ██║   ██║   ██║   ██║██║     ██║   ██║   ██║██╔══╝  ╚════██║//
// ╚██████╔╝   ██║   ██║███████╗██║   ██║   ██║███████╗███████║//
//  ╚═════╝    ╚═╝   ╚═╝╚══════╝╚═╝   ╚═╝   ╚═╝╚══════╝╚══════╝//
/////////////////////////////////////////////////////////////////


// ███████╗██████╗  █████╗  ██████╗██╗███╗   ██╗ ██████╗ 
// ██╔════╝██╔══██╗██╔══██╗██╔════╝██║████╗  ██║██╔════╝ 
// ███████╗██████╔╝███████║██║     ██║██╔██╗ ██║██║  ███╗
// ╚════██║██╔═══╝ ██╔══██║██║     ██║██║╚██╗██║██║   ██║
// ███████║██║     ██║  ██║╚██████╗██║██║ ╚████║╚██████╔╝
// ╚══════╝╚═╝     ╚═╝  ╚═╝ ╚═════╝╚═╝╚═╝  ╚═══╝ ╚═════╝ 
.reset {
    margin: 0;
    padding: 0;
}

.p0 {
    padding: 0;
}

.p2r {
    padding: 2rem;
}

.p14r {
    padding: 1.4rem;
}

.pl1r {
    padding-left: 1rem;
}

.pl05r {
    padding-left: 0.5rem;
}

.pt05r {
    padding-top: 0.5rem;
}

.mt2r {
    margin-top: 2rem;
}

.m0 {
    margin: 0;
}

// ███████╗██╗███████╗██╗███╗   ██╗ ██████╗ 
// ██╔════╝██║╚══███╔╝██║████╗  ██║██╔════╝ 
// ███████╗██║  ███╔╝ ██║██╔██╗ ██║██║  ███╗
// ╚════██║██║ ███╔╝  ██║██║╚██╗██║██║   ██║
// ███████║██║███████╗██║██║ ╚████║╚██████╔╝
// ╚══════╝╚═╝╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝ 

.fill-view {
    height: 100%;
    width: 100%;
}

.h2r {
    height: 2rem;
}

//  █████╗ ██╗     ██╗ ██████╗ ███╗   ██╗███╗   ███╗███████╗███╗   ██╗████████╗
// ██╔══██╗██║     ██║██╔════╝ ████╗  ██║████╗ ████║██╔════╝████╗  ██║╚══██╔══╝
// ███████║██║     ██║██║  ███╗██╔██╗ ██║██╔████╔██║█████╗  ██╔██╗ ██║   ██║   
// ██╔══██║██║     ██║██║   ██║██║╚██╗██║██║╚██╔╝██║██╔══╝  ██║╚██╗██║   ██║   
// ██║  ██║███████╗██║╚██████╔╝██║ ╚████║██║ ╚═╝ ██║███████╗██║ ╚████║   ██║   
// ╚═╝  ╚═╝╚══════╝╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝   

.bold {
    font-weight: 700;
}

.italic {
    font-style: italic;
}

.row {
    display: flex;
}

.fs-20 {
    font-size: 2rem;
}

.pl-30 {
    padding-left: 3rem;
}

.pr-30 {
    padding-right: 3rem;
}

.ml-20 {
    margin-left: 2rem;
}

.p-20 {
    padding: 2rem;
}

.move-right-20 {
    transform: translate(2rem);
}

.move-right-15 {
    transform: translate(1.5rem);
}

.move-left-15 {
    transform: translate(-1.5rem);
}

.move-down-5 {
    transform: translate(0, 0.5rem);
}

.move-up-5 {
    transform: translate(0, -0.5rem);
}

.gap-10 {
    gap: 1rem;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.column {
    display: flex;
    flex-direction: column;
}

.jc-sa {
    justify-content: space-around;
}

.jc-sb {
    justify-content: space-between;
}

.jc-fe {
    justify-content: flex-end;
}

.jc-c {
    justify-content: center;
}

.ai-c {
    align-items: center;
}

.ta-c {
    text-align: center;
}

.ta-l {
    text-align: left;
}

.ta-r {
    text-align: right;
}

.ta-j {
    text-align: justify;
}

.upper {
    text-transform: uppercase;
}

.text-transform-none {
    text-transform: none;
}

.tac {
    width: 100%;
    text-align: center;
}

.tal {
    width: 100%;
    text-align: left;
}

.tar {
    width: 100%;
    text-align: right;
}

.taj {
    width: 100%;
    text-align: justify;
}

// POSITIONING

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.pointer {
    cursor: pointer;
}

.fw400 {
    font-weight: 400;
}

.fw500 {
    font-weight: 500;
}
.fw600 {
    font-weight: 600;
}

.fw700 {
    font-weight: 700;;
}

.fsi {
    font-style: italic;
}
//text-shadow

.ts {
    text-shadow: 1px 1px 2px #a5a5a5;
}

.lh08 {
    line-height: 0.8rem;
}

.lh {
    line-height: 2rem;
}

.lh2-5 {
    line-height: 2.5rem;
}

.lh3 {
    line-height: 3rem;
}

.lh4 {
    line-height: 4rem;
}
//
.h100 {
    height: 100%;
}

.w100 {
    width: 100%
}
.w-65 {
    width: 65%;
}

.w50 {
    width: 50%;
}

.cp {
    cursor: pointer;
}

.ca {
    cursor: auto;
}

.oy-h {
    overflow-y: hidden; 
}

.oy-a {
    overflow-y: auto;
}

.o-h {
    overflow: hidden;
}

.o-a {
    overflow: auto;
}

.col-5ths {
    width: 20%;
    padding: 0 0.8rem;
}

.col {
    display: flex;
    flex-direction: column;
}

@media (max-width: 519px) {
    .col-5ths {
        width: 40%;
        margin: auto;
    }
}

@media (min-width: 1121px) and (max-width: 1222px){
    .col-5ths {
        width: 34%;
        margin: auto;
    }
}

.p10 {
    padding: 10px
}

.z1000 {
    z-index: 1000;
}

.z8000 {
    z-index: 8000;
}