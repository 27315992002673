// ██╗     ██╗███╗   ██╗██╗  ██╗███████╗
// ██║     ██║████╗  ██║██║ ██╔╝██╔════╝
// ██║     ██║██╔██╗ ██║█████╔╝ ███████╗
// ██║     ██║██║╚██╗██║██╔═██╗ ╚════██║
// ███████╗██║██║ ╚████║██║  ██╗███████║
// ╚══════╝╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝╚══════╝

// sets the global styling of anchor tags
a {
    font-family: BureauGrotesque, Helvetica, impact, "avenir next condensed heavy",
        "Droid Sans", sans-serif;
    letter-spacing: 0.045rem;
    text-transform: uppercase;
    font-size: 1.3rem;
    text-decoration: none;
}

.link {
    color: $unverified;
    &:hover {
        color: rgb(66, 66, 66);
    }
}

::ng-deep a:focus{
    outline: none !important;
    background-color: transparent !important;
    background: none !important;
}

::ng-deep li:focus{
    outline: none !important;
    background-color: transparent !important;
    background: none !important;
}


