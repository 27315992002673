@mixin password-suggestion-color($background-color) {
    color: #fff;
    border: 0px;
    background-color: $background-color;
  }

.password-suggestion {
    list-style-type: none;
    display: flex;
    padding-top: 10px;
    margin-bottom: 10px !important;

    li {
        padding-right: 10px;

        &.green {
            div {
                @include password-suggestion-color(rgb(38, 209, 89));
            }
        }
        &.red {
            div {
                @include password-suggestion-color(rgb(180, 25, 50));
            }
        }
        div {
            border: 1px solid #797979;
            background: #fff;
            color: #797979;
            font-size: 11px;
            border-radius: 2px;
            width: 26px;
            text-align: center;
            line-height: 26px;
          }
    }
  }
//   .passwordsuggestion li {
//     padding-right: 10px;
//   }
//   .passwordsuggestion li div {
//     border: 1px solid #797979;
//     background: #fff;
//     color: #797979;
//     font-size: 11px;
//     border-radius: 2px;
//     width: 26px;
//     text-align: center;
//     line-height: 26px;
//   }
//   .passwordsuggestion li.greensuggest div {
//     background: rgb(38, 209, 89);
//     color: #fff;
//     border: 0px;
//   }
//   .passwordsuggestion li.redsuggest div {
//     background: rgb(180, 25, 50);
//     color: #fff;
//     border: 0px;
//   }