@import './variables';

:host {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-family: $font-family;
    display: flex;
    flex-direction: column;
    height: 100%;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}