.main-content,
.main-content > .content-wrapper {
  background: rgb(250, 250, 250);
}
.main-hdr {
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
}
.main-hdr-cont h4 {
  text-align: center;
  font-size: 26px;
  color: #000000;
  padding: 25px 0;
  margin: 0;
}
.main-hdr .first-para {
  font-size: 20px;
  text-align: justify;
}
.main-hdr p {
  // color: #000000;
  // font-size: 15px;
  line-height: 30px;
  text-align: justify;
}

li {
  font-size: 16px;
  // padding-bottom: 10px;
}

.para-class {
  font-size: 0.875rem;
  color: black;
  // text-align: justify;
}
