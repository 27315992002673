.mat-stepper-horizontal {
    margin-top: 8px;
  }
  
.mat-form-field {
margin-top: 16px;
}

::ng-deep .mat-step-header .mat-step-icon {
    background-color: $card-text-color;
    color: #fff;
}

::ng-deep .mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: $unverified;
    color: #fff;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: $unverified;
}

::ng-deep .mat-step-icon-state-done {
    background-color: $success
}

::ng-deep .mat-step-header .mat-step-label.mat-step-label-active {
    color: $secondary-icon-color;
}