/* color list status */
@mixin legend-color($background-color, $width: 0) {
  background-color: $background-color;

  @if $width != 0 {
    width: $width;
  } @else {
    border-radius: 50%;
  }

  min-width: 1.5rem;
  min-height: 1.5rem;
}

.legend {
  display: block;
  align-items: center;
  list-style-type: none;

  li {
    font-size: 1.3rem;
    display: flex;
    align-items: center;

    span {
      margin-right: 0.6rem;
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;

      &.yellow {
        &-circle {
          @include legend-color(#ffd54f, 0)
        }
        &-rectangle {
          @include legend-color(#ffd54f, 2rem)
        }
      }
      &.light-pink {
        &-circle {
          @include legend-color(#ffbeef, 0);
        }
        &-rectangle {
          @include legend-color(#ffbeef, 2rem);

        }
      }
      &.dark-pink {
        &-circle {
          @include legend-color($dark-pink-legend, 0);
        }
        &-rectangle {
          @include legend-color($dark-pink-legend, 2rem);
        }
      }
      &.black {
        &-circle {
          @include legend-color(#000, 0);
        }
        &-rectangle {
          @include legend-color(#000, 2rem);
        }
      }
      &.light-blue {
        &-circle {
          @include legend-color(#0db7f0, 0);

          span {
            width: 0.4rem;
            height: 0.4rem;
            background: #fff;
            border-radius: 50%;
            display: block;
            margin: 0 auto;
          }
        }
        &-rectangle {
          @include legend-color(#0db7f0, 2rem);
        }
      }
    }
  }
}